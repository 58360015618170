import ApolloClient, { InMemoryCache } from 'apollo-boost'
import { InMemoryCache as UploadInMemoryCache } from 'apollo-cache-inmemory'

const apolloCache = new UploadInMemoryCache({
  addTypename: false,
})

export const accountNodeUrl =
  process.env.REACT_APP_API_URL === 'dev-build'
    ? 'https://subscriptionx-dev-api.ifca.io/subscriptionx'
    : process.env.REACT_APP_API_URL === 'uat-build'
    ? 'https://subscriptionx-api.ifca.asia/subscriptionx'
    : process.env.REACT_APP_API_URL === 'prod-build'
    ? 'https://subscriptionx-api.ifca.asia/subscriptionx'
    : process.env.REACT_APP_API_URL === 'ifca-build'
    ? 'https://accountx-internal-api.ifca.io/accountx'
    : process.env.REACT_APP_API_URL === 'ifca-uat-build'
    ? 'https://accountx-internal-api.ifca.io/accountx'
    : process.env.REACT_APP_API_URL === 'prod-v5-build'
    ? 'https://subscriptionx-api.ifca.asia/subscriptionx'
    : process.env.REACT_APP_API_URL === 'uat-v5-build'
    ? 'https://subscriptionx-api.ifca.asia/subscriptionx'
    : process.env.REACT_APP_API_URL === 'uat2-v5-build'
    ? 'https://subscriptionx-api.ifca.asia/subscriptionx'
    : process.env.REACT_APP_API_URL === 'prod-eon-build'
    ? 'https://subscriptionx-api.ifca.asia/subscriptionx'
    : process.env.REACT_APP_API_URL === 'uat-eon-build'
    ? 'https://subscriptionx-api.ifca.asia/subscriptionx'
    : 'https://subscriptionx-dev-api.ifca.io/subscriptionx'

export const AccountClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  credentials: 'include',
  uri: accountNodeUrl,
  onError: error => {
    error.graphQLErrors?.map(({ message }) => {
      console.log('message', message)
      if (message === 'session can not be established') {
        // browserHistory.push('/login', {})
      }
    })
  },
})
